import React from "react";
import "./RemoveWishlist.scss";
import deleteicon from "assets/icons/delete.webp";
import { useAppDispatch } from "store/store";
import { removeFromWishlist } from "features/search-result/components/ResultCard/components/WishList/wishlist.slice";
import { getWishlists } from "../../wishlists.slice";
interface IWishList {
  wishlist_status?: boolean;
  room_category?: {
    id: string;
  };
}
const RemoveWishlist: React.FC<IWishList> = (props) => {
  const dispatch = useAppDispatch();
  const manageItemWishlist = (id: any) => {
    dispatch(removeFromWishlist(id));
    dispatch(getWishlists());
  };

  return (
    <>
      <div
        className={`remove-wrapper`}
        onClick={() => manageItemWishlist(props?.room_category?.id)}
      >
        <img loading="lazy" src={deleteicon} alt="delete icon"></img>
      </div>
    </>
  );
};

export default RemoveWishlist;
