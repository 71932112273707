import React from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "store/store";
import { clearToastState, toastSelector } from "./Toast.slice";

import { closeButton, ToastIcons } from "./Toast.config";
import "./Toast.scss";
import { DirectionCheck } from "utils/classnameSwitch";

const getToastIcon = (type: string = "info") => {
  return ToastIcons.filter((icons) => icons.type === type)[0].iconUrl;
};

const Toast: React.FC = () => {
  const {t}=useTranslation();
  const dispatch = useAppDispatch();
  const { isVisible, type, message, dismiss } = useAppSelector(toastSelector);
  let icon: string = '';
  isVisible && (icon = getToastIcon(type));

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("toast")?.classList.add("toast-close");
      dispatch(clearToastState());
    }, dismiss || 3000);
  }, [isVisible, dismiss, dispatch]);

  return (
    <div
      className={`toast-wrapper ${isVisible ? "" : "toast-close"}`}
      id="toast"
      dir={DirectionCheck()}
    >
      <div className={`toast-${type}`}>
        <button
          type="button"
          className="toast-button"
          onClick={(_) => dispatch(clearToastState())}
        >
          <img src={closeButton} alt="close button" loading="lazy"/>
        </button>
        <div className="toast-image">
          <img src={icon} alt={type} loading="lazy"/>
        </div>
        <div className="toast-message">{t(message)}</div>
      </div>
    </div>
  );
};

export default Toast;
