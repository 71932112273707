import ImageCropper from "components/ImageCropper/ImageCropper";
import React, { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import './PersonalInfo.scss';
import TextField from "components/Inputs/TextField";
import imageCompression from 'browser-image-compression'
import profileImg from 'assets/images/profile.webp';
import { SubmitHandler, useForm } from "react-hook-form";
import Modal from "components/Modal/Modal";
import ChangePassword from "../changePassword/ChangePassword";
import { useAppDispatch, useAppSelector } from "store/store";
import { clearProfileState, profile, UpdateUserDetails } from "features/profile/slices/profile.slice";
import { setToastState } from "components/Toast/Toast.slice";
import { clearState, verifyNow, verifyNowStatus } from "features/profile/slices/verifyNow.slice";
import SearchSkeleton from "features/search-property/components/SearchSkeleton/SearchSkeleton";
import PhoneInput from "react-phone-input-2";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { classSwitch } from "utils/classnameSwitch";
import location from "assets/icons/search/location.webp";
import useClickOutside from "hooks/useClickOutside";

type Image = {
    image?: string;
    name: string;
    fileContent: any;
    file: any;
}
interface InformationProps {

}

const PersonalInformation: React.FC<InformationProps> = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { data } = useAppSelector(profile);
    const [email, setEmail] = useState('');
    const [is_email_verified, setEmailVerified] = useState(false);
    const [profile_picture, setProfilePicture] = useState<any>(null);
    const { isError, errorMessage, successMessage, isSucess } = useAppSelector(verifyNowStatus);
    const { error: profileError, errorMessage: profileErrMsg,
        successMessage: profileSuceesMsg, success: profileSucess, loading, data: profileInfo } = useAppSelector(profile);
    const userId: any = localStorage.getItem(`${window.location.host}userId`);

    const imageRef = useRef<any>(null);
    const [cropImageOptions, setCropImageOptions] = useState<Image | null>(null)
    const [isShowChangePwd, toggleChangePwd] = useState(false);
    const [isEditDisabled, setDisableEdit] = useState(true);
    const [isOwner, setOwner] = useState(false);
    const [isSubmit, setSubmit] = useState(false)
    const [phone, setPhone] = useState('')
    const [optionImage, setOptionImage] = useState<Image>({
        name: '',
        fileContent: '',
        file: ''
    });

    const [value, setPlaceValue] = useState("");
    const [country, setCountry] = useState("");
    const [autofillLoc, setPlaces] = useState<any[]>([]);
    const { placePredictions, getPlacePredictions } = useGoogle({ apiKey: process.env.REACT_APP_MAP_TOKEN });

    const node = React.useRef<HTMLDivElement>(null);
    const [toggleOption, setToggle] = useState(false);
    useClickOutside(node, () => { setToggle(false); });

    const [focusCity, setFocusCity] = useState(false);
    const [focusCityBorder, setFocusCityBorder] = useState(false);
    const [focusCountry, setFocusCountry] = useState(false);
    const [focusCountryBorder, setFocusCountryBorder] = useState(false);

    useEffect(() => {
        if(placePredictions.length) {
            setPlaces(placePredictions.map(place => {
                return {
                    id: place.place_id,
                    title: place.structured_formatting.main_text,
                    description: place.description,
                    country: place.terms.slice(-1)[0].value
                }
            }));
        }
    }, [placePredictions]);

    useEffect(() => { setSubmit(false) }, [phone]);

    useEffect(() => {
        if (profileInfo?.role?.includes(2)) {
            setOwner(true);
        }
    }, [profileInfo]);

    useEffect(() => {
        if (profileError) {
            dispatch(setToastState({ type: 'danger', message: profileErrMsg, dismiss: 3500 }));
            dispatch(clearState());
        }
        if (profileSucess) {
            dispatch(setToastState({ type: 'success', message: profileSuceesMsg, dismiss: 3500 }));
            setDisableEdit(true);
            dispatch(clearProfileState());
            dispatch(clearState());
            clearErrors("email");
        }
        // eslint-disable-next-line
    }, [profileError, profileErrMsg, profileSuceesMsg, profileSucess, dispatch, data]);

    useEffect(() => {
        if (isError) {
            dispatch(setToastState({ type: 'danger', message: errorMessage, dismiss: 3500 }));
            dispatch(clearState());
        }
        if (isSucess) {
            dispatch(setToastState({ type: 'success', message: successMessage, dismiss: 3500 }));
            dispatch(clearState());
        }
    }, [isError, errorMessage, successMessage, isSucess, dispatch]);
    const { register, formState: { errors }, handleSubmit, setValue } = useForm<any>();

    const { 
        register: verifyField, 
        formState: { errors: verifyerror }, 
        handleSubmit: verifySubmit, 
        setValue: setVerify, 
        getValues, 
        setError, 
        setFocus, 
        clearErrors 
    } = useForm();

    const setFormData = (data: any) => {
        const { first_name, last_name, phone_number, country, city, is_email_verified, profile_picture, email } = data;
        setEmailVerified(is_email_verified);
        setEmail(email || '');
        profile_picture && setProfilePicture(profile_picture);
        setValue("first_name", first_name || '');
        setValue("last_name", last_name);
        setVerify("email", email || '');
        setPhone(phone_number || '')
        setValue("country", country || '');
        setValue("city", city || '');

        setCountry(country || '');
        setPlaceValue(city || '');
        country && setFocusCountry(true);
        city && setFocusCity(true);
    }

    useEffect(() => {
        data && setFormData(data);
        // eslint-disable-next-line
    }, [setValue, setVerify, data]);

    const updateUserDetails = (data: any, emailValue: any) => {
        setSubmit(true);
        const { city, country, first_name, last_name } = data;
        const fd = new FormData();
        fd.append('id', userId);
        fd.append('email', emailValue || email);
        fd.append('profile_picture', profile_picture);
        fd.append('first_name', first_name);
        fd.append('last_name', last_name);
        fd.append('phone_number', phone);
        fd.append('country', country);
        fd.append('city', city);
        if (phone?.length > 9 && phone?.length < 16) {
            dispatch(UpdateUserDetails(fd))
            setSubmit(false);
        }
    }

    const onSubmit: SubmitHandler<any> = (data) => {
        const emailValue = getValues("email")?.trim();
        if (profileInfo?.is_email_verified) {
            updateUserDetails(data, emailValue);
        }
        else {
            // email requirement validation for unverified user
            if (!emailValue || !/^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(emailValue)) {
                setError("email", { type: "required" });
                setFocus("email");
            }
            else {
                clearErrors("email");
                updateUserDetails(data, emailValue);
            }
        }
    };

    const [passwordShowIcon, setPasswordShowIcon] = useState(false);

    const toggle = () => {
        toggleChangePwd(!isShowChangePwd);
    }

    const ChangePasswordCom = <ChangePassword email={email} hide={toggle} />

    const editProfileDetails = () => {
        setDisableEdit(false);
    }

    const verifyEmail: SubmitHandler<any> = (mail) => {
        dispatch(verifyNow(mail));
    }

    const fileSelectHandler = async (event: any, type: string) => {
        try {
            if (event?.target?.files[0]?.size <= 2097152) {
                const image = event.target.files[0]
                const fileName = event.target.files[0].name
                const maxSize = {
                    maxSizeMB: 2,
                    useWebWorker: true,
                    maxWidthOrHeight: 500
                }
                const compressedFile = await imageCompression(image, maxSize)
                const BlobToFile = new File([compressedFile], fileName, {
                    lastModified: Date.now(),
                    type: compressedFile.type
                })
                getImageUrl(BlobToFile, type)
                imageRef.current.value = ''
            } else {
                dispatch(setToastState({
                    type: 'danger',
                    message: t('toast.fileUpload'),
                    dismiss: 3500
                }))
            }
        } catch (err) {
            dispatch(setToastState({ type: 'danger', message: t(`toast.driveImgError`), dismiss: 3500 }));
        }
    }

    const getImageUrl = (file: any, type: string) => {
        const reader = new FileReader()
        file && reader.readAsDataURL(file)
        reader.onload = () => {
            const fileContent = reader.result;
            setCropImageOptions({
                image: file.name,
                name: file.name,
                fileContent,
                file
            })
        }
    }

    const handleCroppedImage = async (file: { fileContent: any; name: string; }) => {
        setCropImageOptions(null)
        if (file?.fileContent) {
            const image = dataURLtoFile(file.fileContent, file.name)
            const maxSize = {
                maxSizeMB: 0.1,
                maxWidthOrHeight: 300,
                useWebWorker: true
            }
            const compressedFile = await imageCompression(image, maxSize);
            const BlobToFile = new File([compressedFile], file.name, {
                lastModified: Date.now(),
                type: compressedFile.type
            })
            setOptionImage({
                name: file.name,
                fileContent: file.fileContent,
                file: BlobToFile
            })
            setProfilePicture(BlobToFile);
        }
    }

    const dataURLtoFile = (dataurl: string, filename: string) => {
        const arr = dataurl.split(',')
        const mime = dataurl.substring(dataurl.indexOf(":") + 1, dataurl.indexOf(";"));
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], filename, { type: mime })
    }

    const renderVerifyEmail = () => {
        if (is_email_verified) {
            return <button className="verified"> <i className="fa fa-check" aria-hidden="true"></i>{t('personalInfo.verified')} </button>
        } else {
            return <button className="btn-green" > {t('personalInfo.verify')} </button>
        }
    }

    const fileUpload = () => {
        imageRef.current.click();
    }

    const cancel = () => {
        data && setFormData(data);
        clearErrors("email");
        setDisableEdit(!isEditDisabled);
    }

    const removePhoto = () => {
        setOptionImage({
            name: '',
            fileContent: '',
            file: ''
        });
        setProfilePicture('');
    }

    return (
        <div className="personal-info">
            {!loading ?
                <Fragment>
                    <div className="title-action ">
                        <div className="title-name">{t('personalInfo.title')}</div>
                       {!isOwner && <div className="action">
                            {isEditDisabled ? (<button className="edit-btn" onClick={editProfileDetails}><i className="fas fa-pencil-alt"></i>{t('personalInfo.edit')}</button>) : (
                                <div className="btn-action-desk">
                                    <button className="cancel-btn" onClick={cancel}>{t('personalInfo.cancel')}</button>
                                    <button className="save-btn" onClick={handleSubmit(onSubmit)}><i className="fas fa-save"></i>{t('personalInfo.save')}</button>
                                </div>
                            )}

                        </div>}
                    </div>
                    <div className="profile-cnt-wrap">
                        <div className="profile-img-wrapper">
                            <div className="img-wrap">
                                {loading ? <SearchSkeleton></SearchSkeleton> :
                                    <img loading="lazy" 
                                        className="profile-img" 
                                        src={ optionImage?.fileContent ? optionImage?.fileContent : (profile_picture || profileImg)} 
                                        alt="profile" 
                                        onError={(e: any) => e.target.src = profileImg}
                                    />
                                }
                                {!isEditDisabled &&
                                    <Fragment>
                                        <button className="profile-edit" onClick={fileUpload}><i className="fas fa-pencil-alt"></i></button>
                                        <input
                                            id='selectImage'
                                            accept='image/*' hidden
                                            type="file"
                                            onChange={(event) => fileSelectHandler(event, 'image')}
                                            ref={imageRef}
                                        />
                                        {(optionImage?.fileContent || profile_picture) && <button className="remove-photo" onClick={removePhoto}>{t('personalInfo.removePic')}</button>}
                                    </Fragment>
                                }

                            </div>
                            {data && <h2>{`${data?.first_name} ${data?.last_name}`}</h2>}
                        </div>
                        <div className="form-wrap">
                            <form onSubmit={verifySubmit(verifyEmail)} noValidate>
                                <div className="form-field btn-wrap">
                                    <TextField
                                        label={t('footer.contact.mail')}
                                        placeholder={t('footer.contact.mail')}
                                        register={verifyField}
                                        name="email"
                                        type="email"
                                        disabled={is_email_verified}
                                        errors={verifyerror}
                                        rules={{
                                            required: true,
                                            pattern: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                        }}
                                    />
                                    {renderVerifyEmail()}
                                </div>
                            </form>
                            <form noValidate>
                                <div className="form-field">
                                    <TextField
                                        label={t('register.firstname')}
                                        placeholder={t('register.firstname')}
                                        register={register}
                                        name="first_name"
                                        type="text"
                                        disabled={isEditDisabled}
                                        errors={errors}
                                        rules={{
                                            required: true,
                                            maxLength: 25
                                        }}
                                    />
                                </div>
                                <div className="form-field">
                                    <TextField
                                        label={t('register.lastname')}
                                        placeholder={t('register.lastname')}
                                        register={register}
                                        name="last_name"
                                        type="text"
                                        disabled={isEditDisabled}
                                        errors={errors}
                                        rules={{
                                            required: true,
                                            maxLength: 25
                                        }}
                                    />
                                </div>
                                <div className="field text-field form-field profile-phone">
                                    <PhoneInput
                                        containerClass={`${isEditDisabled ? 'disabled-input' : ''}`}
                                        inputClass={'input'}
                                        country={'ae'}
                                        disabled={isEditDisabled}
                                        countryCodeEditable={false}
                                        value={phone}
                                        onChange={phone => setPhone(phone as any)}
                                        placeholder={t('footer.contact.phone')}
                                        specialLabel={t('register.phone')}
                                        preferredCountries={['ae']}
                                        defaultMask='..................' 
                                        inputProps={{
                                            name: 'phone',
                                            required: true
                                        }}
                                    />
                                    {phone?.length < 4 && isSubmit && <p className="help is-danger">Phone Number is required</p>}
                                    {phone?.length > 4 && (phone?.length < 10 || phone?.length >= 15)
                                        && isSubmit && <p className="help is-danger">Phone Number is invalid</p>}
                                </div>

                                {/* City autofill field */}
                                <div className="form-field" style={{position: "relative"}} ref={node}>
                                    <div className={`field text-field mt-2`}>
                                        <label className={`label 
                                            ${focusCity ? 'show': 'hide'} ${focusCityBorder ? 'has-text-primary': ''}`}
                                        >{t('personalInfo.city')}</label>
                                        <div className="control has-icons-right">
                                            <input autoComplete="off"
                                                id="autocomplete-i"
                                                className={`text-field input ${focusCityBorder ? 'is-primary': ''}`}
                                                value={value}
                                                placeholder={focusCity ? '' : t('personalInfo.city')}
                                                onChange={(evt) => {
                                                    getPlacePredictions({
                                                        input: evt.target.value,
                                                        types: ["(cities)"]
                                                    });
                                                    setPlaceValue(evt.target.value);
                                                    setValue("city", evt.target.value);                                    
                                                    !evt.target.value.trim() && setPlaces([]);
                                                }}
                                                onClick={_ => setToggle(!toggleOption)}
                                                disabled={isEditDisabled}
                                                onFocus={_ => {
                                                    setFocusCity(true);
                                                    setFocusCityBorder(true);
                                                }}
                                                onBlur={_ => {
                                                    !value && setFocusCity(false);
                                                    setFocusCityBorder(false);
                                                }}
                                            />
                                            {autofillLoc.length > 0 && toggleOption ?
                                                <div className="locations-dropdown"> 
                                                    {autofillLoc.map(loc => {
                                                        return (
                                                        <div
                                                            className="locations-dropdown-item"
                                                            id={loc.id}
                                                            onClick={(_) => {
                                                                setCountry(loc.country);
                                                                setPlaceValue(loc.title);
                                                                setToggle(false);
                                                                setValue("city", loc.title); 
                                                                setValue("country", loc.country);
                                                            }}
                                                        >
                                                            <h4>
                                                            <img loading="lazy"
                                                                src={location}
                                                                alt="Location Icon"
                                                                className={classSwitch(
                                                                "mr-3"
                                                                )}
                                                            ></img>
                                                            {loc.title}
                                                            </h4>
                                                            <h6>{loc.description}</h6>
                                                        </div>
                                                        );
                                                    })}
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Country field */}
                                <div className="form-field" style={{position: "relative"}}>
                                    <div className={`field text-field mt-2`}>
                                        <label className={`label 
                                            ${focusCountry ? 'show': 'hide'} ${focusCountryBorder ? 'has-text-primary': ''}`}
                                        >{t('personalInfo.country')}</label>
                                        <div className="control has-icons-right">
                                            <input
                                                autoComplete="off"
                                                id="autocomplete-ii"
                                                className={`text-field input ${focusCountryBorder ? 'is-primary': ''}`}
                                                value={country}
                                                placeholder={focusCountry ? '' : t('personalInfo.country')}
                                                disabled={isEditDisabled}
                                                onChange={(evt) => {
                                                    setCountry(evt.target.value);
                                                    setValue("country", evt.target.value);
                                                }}
                                                onFocus={_ => {
                                                    setFocusCountry(true);
                                                    setFocusCountryBorder(true);
                                                }}
                                                onBlur={_ => {
                                                    !country && setFocusCountry(false);
                                                    setFocusCountryBorder(false);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {/* Hide change password section for social login users */}
                            {
                                !data.is_social_media_login ? 
                                    <div className="form-field btn-wrap">
                                    {
                                        isEditDisabled || !isShowChangePwd ?
                                            <TextField
                                                label={t('common.pwd')}
                                                placeholder={t('common.pwd')}
                                                register={register}
                                                name="password"
                                                disabled={true}
                                                type={passwordShowIcon ? "text" : "password"}
                                                errors={errors}
                                                defaultValue="***********"
                                                rules={{
                                                    required: true,
                                                }}
                                                passwordVisibibility={(status) => setPasswordShowIcon(status)}
                                            /> :
                                            <TextField
                                                label={t('common.pwd')}
                                                placeholder={t('common.pwd')}
                                                register={register}
                                                name="password"
                                                disabled={true}
                                                type={passwordShowIcon ? "text" : "password"}
                                                errors={errors}
                                                defaultValue="***********"
                                                icon={passwordShowIcon ? "show" : "hide"}
                                                rules={{
                                                    required: true,
                                                }}
                                                passwordVisibibility={(status) => setPasswordShowIcon(status)}
                                            />
                                    }
                                        <button className="btn-green" onClick={toggle}>{t('personalInfo.changepwd')}</button>
                                    </div> : null
                            }
                            {!isEditDisabled &&
                                <div className="action-btn-wrap">
                                    <button className="cancel-btn" onClick={cancel}>{t('personalInfo.cancel')}</button>
                                    <button className="save-btn" onClick={handleSubmit(onSubmit)}><i className="fas fa-save"></i>{t('personalInfo.save')}</button>
                                </div>
                            }
                            <Modal
                                isShown={isShowChangePwd}
                                hide={toggle}
                                modalContent={ChangePasswordCom}
                                buttonName={t('personalInfo.save')}
                                title={t('personalInfo.changepwd')}
                            />
                            <ImageCropper
                                active={Boolean(cropImageOptions)}
                                options={cropImageOptions}
                                src={cropImageOptions && cropImageOptions.fileContent
                                    ? cropImageOptions.fileContent
                                    : ''}
                                style={{ height: 450, width: 450, x: 1, y: 1 }}
                                croppedImage={handleCroppedImage}
                            />
                        </div>
                    </div>
                </Fragment> : 
                <div className="loading-spin">
                    <SearchSkeleton></SearchSkeleton>
                </div>
            }
        </div>


    )
}

export default PersonalInformation;