/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import Coupon from "./coupon/Coupon";
import { useAppDispatch, useAppSelector } from "store/store";
import { clearState, couponsSelector, fetchCoupons } from "./coupons.slice";
import { setToastState } from "components/Toast/Toast.slice";

import "./Coupons.scss";
import { CouponsData } from "./coupons.interface";
import noResults from "assets/images/coupons/no-results.webp";
import CouponLoader from "./CouponLoader/CouponLoader";
import ReferAndEarn from "../refer-and-earn/ReferAndEarn";
import {
  isReferralActive,
  referralSelector,
} from "../refer-and-earn/refer-and-earn.slice";
import { Context } from "components/app/Wrapper";
import { stayDetailSelector } from "features/stay-details/stay-details.slice";

const Coupons: React.FC<{ applyCoupon?: (id: string) => any }> = (props) => {
  const { t } = useTranslation();
  const context: any = useContext(Context);
  const dispatch = useAppDispatch();
  const { priceSelected } = useAppSelector(stayDetailSelector);
  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    coupons,
    selectedCouponId,
  } = useAppSelector(couponsSelector);
  useEffect(() => {
    dispatch(fetchCoupons({total: priceSelected.rate?.total_base_rate}));
    dispatch(isReferralActive());
  }, [dispatch, context.locale, priceSelected]);

  useEffect(() => {
    if (isError) {
      dispatch(
        setToastState({ type: "danger", message: errorMessage, dismiss: 4500 })
      );
      dispatch(clearState());
    }
  }, [isError, dispatch, errorMessage, isSuccess]);

  const [openModal, setOpenModal] = useState(false);
  const referAndEarnModal = (status: boolean) => setOpenModal(status);
  const { isReferralActive: isActive, discount } = useAppSelector(referralSelector);

  if (isSuccess && coupons.length === 0) {
    return (
      <>
        <div className="coupons-wrapper no-result-coupon">
          <img loading="lazy" src={noResults} alt="No coupon found" />
          <h3>{t('coupons.noCoupon')}</h3>
          {isActive && discount ? (
            <>
              <button
                className="edit-btn mt-4"
                onClick={(_) => referAndEarnModal(true)}
              >
                <i className="fas fa-pencil-alt"></i> {t('coupons.refer')}
              </button>
              <p className="mt-0 mt-4">{t('coupons.or')}</p>
            </>
          ) : null}
          <a href="/" className="mt-4">
            {t('coupons.goHome')}
          </a>
        </div>
        {openModal ? <ReferAndEarn onClose={referAndEarnModal} /> : null}
      </>
    );
  }

  return (
    <>
      <div className="coupons-wrapper">
        <div className="coupons-section">
          <div className="coupons-header-grid corner-items">
            <span className="title">{t('coupons.title')}</span>
            {!isFetching && isActive && discount ? (
              <button
                className="edit-btn mt-4"
                onClick={(_) => referAndEarnModal(true)}
              >
                <i className="fas fa-pencil-alt"></i> {t('coupons.refer')}
              </button>
            ) : null}
          </div>
        </div>

        {/* Coupons list */}
        <div id="accordian_parent">
          {isFetching ? (
            <CouponLoader />
          ) : (
            coupons.map((coupon: CouponsData, index) => (
              <div
                className={`accordian-container ${
                  index === coupons.length - 1 ? "no-border-bottom" : ""
                } ${
                  selectedCouponId === coupon.id
                    ? "selected-coupon-highlight"
                    : ""
                }`}
              >
                <Coupon
                  data={coupon}
                  applyCoupon={(id: string) =>
                    props.applyCoupon && props.applyCoupon(id)
                  }
                />
              </div>
            ))
          )}
        </div>
        {openModal ? <ReferAndEarn onClose={referAndEarnModal} /> : null}
      </div>
    </>
  );
};
export default Coupons;
