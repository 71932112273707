import { useContext } from "react";
import "./Attractions.scss";
import Carousel from "react-elastic-carousel";
import { Context } from "components/app/Wrapper";

const images = require.context("assets/images/attractions", false, /\.webp$/);

interface IAttractions {
  name: string;
  description?: string;
  image: string;
}
interface AttractionsProps {
  attractions: IAttractions[];
  title: string;
}
const Attractions2Carousel: React.FC<AttractionsProps> = ({
  attractions,
  title,
}) => {
  const responsive = [
    { width: 1, itemsToShow: 2, outerSpacing: 10 },
    { width: 550, itemsToShow: 3 },
    { width: 850, itemsToShow: 3 },
    { width: 1160, itemsToShow: 4 },
    { width: 1450, itemsToShow: 4 },
    { width: 1750, itemsToShow: 4 },
  ];
  const context: any = useContext(Context);
  return (
    <>
      <div className="container is-full-fullhd pb-4">
        <div className="fw-500 attractionsTitle">{title}</div>
      </div>
      <div className="container is-full-fullhd mt-5">
        <Carousel isRTL={context.locale === "ar"} breakPoints={responsive}>
          {attractions.map((item, i) => {
            const mg = images(`./${item.image}.webp`).default
            return (
              <div className="module" style={{ backgroundImage: `url(${mg})` }} key={i}>
                <div className="hover-card attraction-title-holder">
                  <div className="title">{item.name}</div>
                  <div className="subtitle">{item.description}</div>
                </div>
                <header>
                  <h1>
                    {item.name}
                  </h1>
                </header>
              </div>

            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default Attractions2Carousel;
