import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import "./Offers.scss";
import viewallIcon from "assets/icons/viewall-arrow.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAppDispatch, useAppSelector } from "store/store";
import { getOffers, offerSelector } from "./offers.slice";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import offerImg from "assets/images/offers/offer2.webp";
import useLocalStorage from "hooks/useLocalStorage";
import { classSwitch, DirectionCheck } from "utils/classnameSwitch";
import { Context } from "components/app/Wrapper";

// eslint-disable-next-line
const Offers: React.FC = () => {
  const context: any = useContext(Context);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isOfersFetched, offers } = useAppSelector(offerSelector);
  const history = useHistory();

  // eslint-disable-next-line
  const [contDir, setContDir] = useLocalStorage("contentDir", "ltr");

  useEffect(() => {
    dispatch(getOffers());
  }, [dispatch, context.locale]);

  const deviceReady = () => {
    let devicesetting = {};
    if (window.innerWidth < 1300) {
      devicesetting = {
        className: "center",
        infinite: true,
        centerPadding: "30px",
        slidesToShow: 1,
        speed: 500,
      };
    } else if (window.innerWidth > 1300 && window.innerWidth < 1470) {
      devicesetting = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "50px",
        slidesToShow: 1,
        speed: 500,
      };
    } else if (window.innerWidth > 1470 && window.innerWidth < 1880) {
      devicesetting = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "200px",
        slidesToShow: 1,
        speed: 500,
      };
    } else {
      devicesetting = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "300px",
        slidesToShow: 1,
        speed: 500,
      };
    }
    return devicesetting;
  };
  const [dimensions, setDimensions] = useState(deviceReady());
  useEffect(() => {
    function handleResize() {
      setDimensions(deviceReady());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const settings = {
    ...dimensions,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: (
      <div>
        <button
          className="sc-bdnxRM cGKbZh rec rec-arrow rec rec-arrow-left"
          type="button"
        >
          <img loading="lazy" src={viewallIcon} alt="view all"></img>
        </button>
      </div>
    ),
    nextArrow: (
      <div>
        <button
          className="sc-bdnxRM cGKbZh rec rec-arrow rec rec-arrow-right"
          type="button"
        >
          <img loading="lazy" src={viewallIcon} alt="view all"></img>
        </button>
      </div>
    ),
  };

  const handleRedirection = (e: any, item: any) => {
    e.preventDefault();
    history.push(`/stay-details/${item.slug}`);
  };

  const buildStayText = (data: any) => {
    let text = "";
    if (data?.arrival_date_gt && data?.departure_date_lt) {
      text = `${t('offers.fromTxt')} ${format(new Date(data?.arrival_date_gt), "LLL dd")} ${t('offers.toText')} ${format(new Date(data.departure_date_lt), "LLL dd")}`;
      text = data?.length_of_stay ? `${t('offers.losTxt1')} ${data.length_of_stay} ${t('offers.losTxt3')} ${text}` : `${t('offers.losTxt1')} ${text}`;
      return text;
    }
    if (data?.length_of_stay) {
      text = `${t('offers.losTxt1')} ${data.length_of_stay} ${t('offers.losTxt2')}`
    }
    return text;
  }

  return (
    <>
      {isOfersFetched && offers?.length !== 0 && (
        <div className="section home-offers">
          <h1 className="has-text-centered all-title">
            {t('home.homeOffer.title')}
          </h1>
          <div className="section mt-4 container deal-content">
            <div className="level is-flex">
              <div className="deals">{t('home.homeOffer.deal')}</div>
              <div className="level-item"></div>
              <div className="level-item"></div>
              <div
                className="level-item offers-viewall"
                onClick={() => history.push("/offers")}
              >
                {t('home.homeOffer.viewAll')}
                <img loading="lazy"
                  alt="view-all"
                  src={viewallIcon}
                  className={`${classSwitch("ml-2")} view-all`}
                ></img>
              </div>
            </div>
          </div>
          <div
            className="section pt-1"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            {isOfersFetched && (
              <Slider {...settings}>
                {offers.map((item, index) => (
                  <div className="box offers-card" key={index}>
                    <div className="columns"  dir={DirectionCheck()}>
                      <div
                        className="column offerBg"
                        style={{
                          backgroundImage: `url(${
                            item.image?.url ? item.image?.url : offerImg
                          })`,
                        }}
                      >
                        {/* <img loading="lazy" src={offer1}></img> */}
                      </div>
                      <div className="column offer-data">
                        <div className="title">{item.property.name}</div>
                        <div className="text fw-500 sub">
                          {item.offer_description.description_1}
                        </div>
                        {item.offer_price && (
                          <div className="value">AED {item.offer_price}</div>
                        )}
                        <div className="validity">
                          Valid Until{" "}
                          {format(new Date(item.expiry), "dd LLL, yyyy")}
                        </div>
                        {((item.arrival_date_gt && item.departure_date_lt) || item.length_of_stay) ? <p className="desc-green">{buildStayText(item)}</p> : null}
                        <div className={`about ${contDir === 'rtl' ? 'textRightAlign' : ''}`}>
                          {item.property.description}
                        </div>
                        <div className="mt-3 booking-grp">
                          <a
                            href={`/stay-details/${item.slug}`}
                            className={`staycae-btn button is-secondary`}
                            onClick={(e) => handleRedirection(e, item)}
                          >
                            {t('home.homeOffer.details')}
                          </a>
                          <a
                            href={`/stay-details/${item.slug}`}
                            className={`staycae-btn button is-primary ${classSwitch("ml-3")} `}
                            onClick={(e) => handleRedirection(e, item)}
                          >
                            {t('home.banner.button')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Offers;
