import React from "react";
import { useTranslation } from "react-i18next";

import { LocationsData } from "features/locations/locations.interface";
import arrow from "assets/icons/locations/arrow.webp";
import './LocationsCard.scss';
import { activeFilters, updateSelection } from "features/search-property/searchproperty.slice";
import { useAppDispatch } from "store/store";
import { useHistory } from "react-router-dom";
import { clearSearchResult } from "features/search-result/searchresult.slice";

const LocationsCard: React.FC<LocationsData> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const searchStays = (props: any) => {
        const params: any = {};
        params['locations'] = props.name;
        params['location'] = props.id;
        params['page'] = null;
        dispatch(clearSearchResult());
        dispatch(activeFilters(params));
        dispatch(
            updateSelection({
                category: "currentLocation",
                value: props.name,
                master: "locations",
                lat: props.latitude,
                long: props.longitude,
            })
        );
        history.push("/search-results");
    }
    return (
        <div className="locations-card" key={props.id}>
            <div className="locations-image" style={{ backgroundImage: `url(${props.image})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center center", borderRadius: "2rem 2rem 0 0" }}>
            </div>
            <div className="locations-card-content">
                <h3 className="locations-card-content-title">{props.name}</h3>
                <p className="locations-card-content-description">{props.description}</p>
                <div className="location-tags">
                    {props.activities.map((activity, i) => <span className="location-tag" key={i} title={activity}>{activity}</span>)}
                </div>
            </div>
            <button className="card-button"
                onClick={() => {
                    searchStays(props)
                }}
            >{t('location.button')}
                <img loading="lazy" src={arrow} alt="" />
            </button>
        </div>
    );
}

export default LocationsCard;