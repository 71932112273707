import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store/rootReducer";

import { HTTPMSG } from "config";
import { LocationsData } from "./locations.interface";
import { get } from "services/get.service";
import { endPoints } from "api/endPoints";

const locationListInitialData: LocationsData[] = [];

export const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  locationList: locationListInitialData
};

export const fetchLocations: any = createAsyncThunk(
  "locations",
  async () => {
    const response: any = await get((endPoints.HOME.LOCATIONS as any));
    return response.data
  }
);

export const fetchTopLocations: any = createAsyncThunk(
  "topLocations",
  async () => {
    const response: any = await get((`${endPoints.HOME.LOCATIONS}?top_locations=True` as any));
    return response.data
  }
);

const locationsSlice = createSlice({
  name: "locationSlice",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = "";
      return state;
    },
  },
  extraReducers: {
    [fetchLocations.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = HTTPMSG[payload.message];
    },
    [fetchLocations.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchLocations.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.locationList = payload.data.locations;
    },
    [fetchTopLocations.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = HTTPMSG[payload?.message];
    },
    [fetchTopLocations.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchTopLocations.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.locationList = payload.data.locations;
    },
  },
});

export const { clearState } = locationsSlice.actions;
export const locationsSelector = (state: RootState) => state.locationsReducer;
const locationsPageReducer = locationsSlice.reducer;
export default locationsPageReducer;
