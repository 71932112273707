/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import BookingCard from "./booking/BookingCard";
import { useAppDispatch, useAppSelector } from "store/store";
import { getBookings, bookingProfileSelector } from "./booking.slice";
import "./Booking.scss";
import { Tab, Tabs } from "features/our-services/components/Tabs/Tabs";
import nobooking from "assets/images/nobooking.webp";
import WishListLoader from "features/search-result/components/ResultCard/components/WishList/components/WishListLoader/WishListLoader";
import { Context } from "components/app/Wrapper";

const Bookings: React.FC = (props) => {
    const { t } = useTranslation();
    const context: any = useContext(Context);
    const dispatch = useAppDispatch();
    const { fetchingBooking, bookings } = useAppSelector(
        bookingProfileSelector
    );

    useEffect(() => {
        dispatch(getBookings());
    }, [dispatch, context.locale]);

    const noBooking = () => {
        return (<div className=" no-result-coupon">
            <img loading="lazy" src={nobooking} alt="No booking details found" />
            <h3>{t("bookings.nobooking")}</h3>
            <a href="/">{t("notFound.btn")}</a>
        </div>)

    }
    return (
        <>
            <div className="coupons-wrapper wishlist-wrap bookings-profile">
                <div className="coupons-section">
                    <div className="coupons-header-grid corner-items">
                        <span className="title">{t("profile.bookings")}</span>
                    </div>
                </div>

                {/* Coupons list */}
                <div id="accordian_parent" className="wishlist-wrapper-di">
                    {fetchingBooking ? (
                        <WishListLoader />
                    ) : (
                        <>
                            <Tabs>
                                <Tab label={t('bookings.coming')} tabName={t('bookings.coming')}>
                                    {bookings?.upcoming_bookings?.length !== 0 ? <div className=" card-spacer">
                                        {bookings?.upcoming_bookings.map((booking, index) => (
                                            <BookingCard {...booking} tab={"Upcoming"} />
                                        ))}
                                    </div> : noBooking()}

                                </Tab>
                                <Tab label={t('bookings.past')} tabName={t('bookings.past')}>
                                    {bookings.past_bookings.length !== 0 ? <div className=" card-spacer">
                                        {bookings?.past_bookings.map((booking, index) => (
                                            <BookingCard {...booking} tab={"Past"} />
                                        ))}
                                    </div> : noBooking()}
                                </Tab>
                            </Tabs>

                        </>
                    )}
                </div>
            </div>
        </>
    );
};
export default Bookings;
