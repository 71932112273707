import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { getLocalStore } from "utils/encryption";
import {  isOwnerDashboard, profile } from "features/profile/slices/profile.slice";
import {  useAppDispatch, useAppSelector } from "store/store";
import HeaderOptions from "../header-options/HeaderOptions";

import { Context } from "components/app/Wrapper";
import avatar from "assets/images/profile.webp";
import OwnerHeaderOption from "../owner-header-option/OwnerHeaderOption";
import SwitchToOwner from "../switchToOwner/SwitchToOwner";
import { images } from "../Header";
import { searchSelector, activeFilters } from "features/search-property/searchproperty.slice";
import { updateDates } from "features/stay-details/stay-details.slice";

interface IRespHeader {
    onClick: (props: any) => any;
    onLogout: () => any;
}

const ResponsiveHeader: React.FC<IRespHeader> = (props) => {
    const { t } = useTranslation();
    const { isLogin } = { isLogin: Boolean(getLocalStore(`${window.location.host}AUTH_DETAILS`)) };
    const location = useLocation();
    const context: any = useContext(Context);
    const dispatch = useAppDispatch();
    const { data, inOwnerDashboard, isOwner } = useAppSelector(profile);
    const [isNotOwnerRoute, setOwnerRoute] = useState<boolean>(false);
    const { locations, checkin, guests, checkout } = useAppSelector(searchSelector);

    const setContextLanguage = () => {
        if (window.location.pathname.includes('search-results')) {
            const params: any = {};
            params['locations'] = locations.currentLocation ?? null;
            params['latitude'] = locations.lat ?? null;
            params['longitude'] = locations.long ?? null;
            params['from'] = checkin.formattedD ?? null;
            params['to'] = checkout.formattedD ?? null;
            params['guests'] = guests.currentGuests.adult > 0 ? guests.currentGuests.adult : null;
            params['page'] = 1;
            params['location'] = locations.id ?? null;
            dispatch(activeFilters(params));
        }
        if (window.location.pathname.includes('stay-details'))
            dispatch(updateDates(false))
        let changeLang = context.locale === 'ar' ? 'en' : 'ar';
        context.selectLanguage(changeLang)
    }

    useEffect(() => {
        if (location.pathname.includes('owner') || (inOwnerDashboard && location.pathname.includes('profile'))) {
            dispatch(isOwnerDashboard(true));
            setOwnerRoute(true);
        }
        else {
            dispatch(isOwnerDashboard(false));
            setOwnerRoute(false);
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    return (
        <div className="responsive-header">
            <span style={{ width: "35px", height: "35px" }} onClick={_ => props.onClick(false)}>
                <i className="fa fa-times close-btn"></i>
            </span>
            <div className="responsive-header-wrapper">
                {isLogin ?
                    <div className="profile">
                        <img
                            className="pfp"
                            src={data?.profile_picture || avatar}
                            alt="profile"
                            onError={(e: any) => e.target.src = avatar}
                            loading="lazy"
                        />
                        <div className="profile-content">
                            {(isOwner) && 
                                <div style={{position: "absolute", top: "0"}}>
                                    <SwitchToOwner isNotOwner={isNotOwnerRoute} />
                                </div>
                            }
                            <span className="profile-content-options">
                                <a className="profile-optn" href="/profile">{t("common.profile")}</a>
                                <span className="logout-optn" onClick={props.onLogout}>{t("common.logout")}</span>
                            </span>
                        </div>
                    </div>
                    :
                    <Link
                        to={{
                            pathname: `/login`,
                            state: {
                                prevPath: location.pathname,
                            },
                        }}
                        className="navbar-item text-primary"
                    >
                        {t("landing.register")}
                    </Link>
                }
                {  !isNotOwnerRoute ? 
                    <div className="navbar-item has-dropdown lang-menu" onClick={() => setContextLanguage()}>
                        <a className="navbar-link" href="#!">
                            <img src={images(`./${context.locale === 'en' ? 'ar' : 'en'}.webp`).default} alt="locales" loading="lazy"></img>
                            {context.locale === 'en' ? 'عربى' : 'en'}
                        </a>
                    </div> : null
                }
                {!inOwnerDashboard ? <HeaderOptions /> : <OwnerHeaderOption onClick={props.onClick} />}
            </div>
        </div>
    );
}

export default ResponsiveHeader;
