import React from "react";
import { useTranslation } from "react-i18next";
import { useTimer } from "react-timer-hook";

import timer from 'assets/icons/timer.webp';

import './Timer.scss';

interface ITimer {
    expiryTimestamp: number;
    onExpire?: (status: boolean) => any;
}

export const timeStampToDate = (timestamp: number) => {
    const dateString: string[] = new Date(timestamp).toDateString().split(" ");
    dateString.shift()
    return `${dateString[1]}-${dateString[0]}-${dateString[2]}`;
}

export const prependZero = (value: number) => {
    const stringVal = String(value);
    return stringVal.length === 1 ? `0${stringVal}`: stringVal;
}

const Timer: React.FC<ITimer> = (props) => {
    const { t } = useTranslation();
    const { expiryTimestamp } = props as any;
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning
    } = useTimer({ expiryTimestamp, onExpire: () =>  props.onExpire && props.onExpire(true)});

    return (
        <> 
            <div className="timer-wrapper">
                {isRunning ? 
                    <>
                        <span className="expires-in text-separator">{days ? t('offers.valid'): t('offers.expire') }</span>
                        { days ?
                            <> 
                                <span className="align calender">
                                    <i className="far fa-calendar-check color" style={{fontSize: "20px"}}></i>
                                    <span className="timer general-calender">{timeStampToDate(expiryTimestamp)}</span>
                                </span>
                            </> 
                            : 
                            <>
                                <span className="align">
                                    <img loading="lazy" src={timer} alt="timer" className="timer-icon" />
                                    <span className="timer">{prependZero(hours)}:{prependZero(minutes)}:{prependZero(seconds)}</span> 
                                </span>
                            </>
                        }
                    </>
                    :
                    <span className="expires-in text-separator">Expired</span>
                }
            </div>
        </>
    )
}

export default Timer;
