import React from "react";
import { useTranslation } from "react-i18next";
import { WishlistsProps } from "../wishlist.interface";
import placeholder from "assets/images/result/placeholder.webp";
import star from "assets/icons/search/star.webp";
import { Amenities } from "./Amenities/Amenities";
import { Services } from "./Services/Services";
import bathroom from "assets/icons/search/bathroom.webp";
import bed from "assets/icons/search/bed.webp";
import guest from "assets/icons/search/guests.webp";
import RemoveWishlist from "./RemoveWishlist/RemoveWishlist";
import { useHistory } from "react-router";
import { updateDates } from "features/stay-details/stay-details.slice";
import { useAppDispatch } from "store/store";

const WishlistCard: React.FC<WishlistsProps> = (data: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleRedirection = (e: any, data: any) => {
    e.preventDefault();
    dispatch(updateDates(false));
    history.push(`/stay-details/${data.room_category.slug}`);
  };

  return (
    <div className={`columns wishlist-columns`}>
      <div
        className={`wishlist-image-container ${
          data.images.length ? "" : "placeholder-image-style"
        } ${(!data.price || !data.is_active) && !data.images.length ? "grayscale" : ""}`}
      >
        {data.images.length ? (
          <img loading="lazy"
            src={data.images[0].url}
            className={`wishlist-image ${(!data.price || !data.is_active) ? "grayscale" : ""}`}
            alt={data?.room_category?.nice_name || data?.room_category?.name}
          ></img>
        ) : (
          <img loading="lazy"
            src={placeholder}
            alt="placeholder-img"
            className={`wishlist-image ${
              (!data?.price || !data?.is_active) && data?.images.length ? "grayscale" : ""
            }`}
          ></img>
        )}
        <RemoveWishlist {...data} />
      </div>
      <div
        className={`wishlist-contents ${(!data.price || !data.is_active) ? "disabled-card" : ""}`}
      >
        <div className="wishlist-name">
          <a
            href={`/stay-details/${data.room_category.slug}`}
            className="result-card-title highlight-onhover"
            style={{ cursor: "pointer" }}
            onClick={(e) => handleRedirection(e, data)}
          >
            {data?.room_category?.nice_name || data?.room_category?.name}
          </a>
          {(!data.price || !data.is_active) && (
            <span className=" unavailable-unit orange">Room Unavailable</span>
          )}
        </div>
        <div
          className="featured-card-rating"
          style={{ cursor: "pointer" }}
          onClick={(e) => handleRedirection(e, data)}
        >
          <div className="wishlist-booking-container">
            {data.price && (
              <span className="wishlist-price">
                AED {data.price?.member?.daily_rate}
                <span className="price-night">/ Night</span>
              </span>
            )}
            <span className="rating-wrap">
              {data.rating.count ? (
                <>
                  <span
                    className={`rating-value ${
                      (!data.price || !data.is_active) ? "grayscale" : ""
                    } ${
                      !data.rating.score
                        ? "rating-value-red"
                        : (data.rating.score as number) < 2
                        ? "rating-value-red"
                        : (data.rating.score as number) > 3
                        ? "rating-value-green"
                        : "rating-value-yellow"
                    }`}
                  >
                    {data.rating.score || 0}
                    <img loading="lazy" alt="alt filler" src={star}></img>
                  </span>
                  <span className="rating-users">
                    {data.rating.count} {t("bookAStay.rated")}
                  </span>
                </>
              ) : (
                <span className="rating-users">
                  {data.rating.count} {t("bookAStay.rated")}
                </span>
              )}
            </span>
            <span>
              {!data.price && (
                <button
                  className={`is-primary staycae-btn button ml-4 book-btn ${
                    (!data.price || !data.is_active) ? "grayscale" : ""
                  }`}
                >
                  {t("wishlist.booknow")}
                </button>
              )}
              {data.price && (
                <button
                  className={`is-primary staycae-btn button ml-4 book-btn`}
                  onClick={(e) => handleRedirection(e, data)}
                >
                  {t("wishlist.booknow")}
                </button>
              )}
            </span>
          </div>
          <div className="amenities-wrap">
            <Amenities {...data} name={t("common.amnty")}></Amenities>
          </div>

          {data.property.services?.length > 0 && (
            <div
              className={`${
                data?.amenities?.length === 0 || data?.amenities?.length === 0
                  ? "amenities-wrap"
                  : ""
              }`}
            >
              <Services {...data} name={t("common.services")}></Services>
            </div>
          )}
          {data.property.services?.length < 120 && (
            <div className="filler-card"></div>
          )}
        </div>
        <div
          className="featured-card-amenities"
          style={{ cursor: "pointer" }}
          onClick={(e) => handleRedirection(e, data)}
        >
          <div className="wishlist-amenties">
            <img loading="lazy" alt="alt filler" src={bed}></img>
            <span className="amenity-no">{data.room_category?.bedrooms}</span>
            <span className="amenity-name">{t("common.bedrooms")}</span>
          </div>
          <div className="wishlist-amenties">
            <img loading="lazy" alt="alt filler" src={bathroom}></img>
            <span className="amenity-no">{data.room_category?.bathrooms}</span>
            <span className="amenity-name">{t("common.bathrooms")}</span>
          </div>
          <div className="wishlist-amenties">
            <img loading="lazy" alt="alt filler" src={guest}></img>
            <span className="amenity-no">
              {data.room_category?.max_occupants}
            </span>
            <span className="amenity-name">{t("common.maxpersons")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishlistCard;
