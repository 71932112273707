import React from "react";
import "./SearchDropDown.scss";
import { classSwitch } from "utils/classnameSwitch";
interface SearchDropDownProps {
  onClick?: () => void;
  title: string;
  toggleView: any;
  children: any;
  subtitle: string;
  image?: any;
  contentClass?: string;
  selectedValue?: string;
  name?: string;
}
const SearchDropDown: React.FC<SearchDropDownProps> = ({
  onClick,
  title,
  toggleView,
  children,
  subtitle,
  image,
  contentClass,
  selectedValue,
  name,
}) => {
  return (
    <>
      <div className="search-bar dropdown is-active ">
        <div className="dropdown-trigger">
          <button
            onClick={onClick}
            className={`dropdown-button button search-dropdown-btn ${toggleView === name ? 'focused-btn' : ''}`}
            aria-haspopup="true"
            aria-controls="dropdown-menu"
          >
            <span className={`fw-500 dropdown-title ${toggleView === name ? 'focused-text' : ''}`}>{title}</span>
            <span
              className={`fw-500 dropdown-subtitle ${selectedValue === "" ? "reg" : "selected-body"
                }`}
            >
              <img loading="lazy" src={image} alt="Location Icon" className={classSwitch("mr-3")}></img>
              {subtitle}
            </span>
            <span className="icon is-small">
              <i className="fa fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        {toggleView === name && (
          <div className={`dropdown-menu ${name === "CHECKOUT" ? 'checkout-menu-mob' : 'checkin-menu-mob'} ${name === "GUESTS" ? 'guest-menu-mob' : ''}`} id="dropdown-menu" role="menu">
            <div className={`dropdown-content search-dropdown ${contentClass}`}>
              {/* <Calendar onChange={(item: any) => setDate(item)} date={date} /> */}
              {children}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchDropDown;
