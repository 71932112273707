import React from "react";
import noResults from "assets/images/coupons/no-results.webp";
import en from "locale/en";

const NoUnits: React.FC = () => {
  return (
    <div
      className="dashboard-main report-grid"
      style={{ minHeight: "80vh", marginTop: "40px" }}
    >
      <div
        className="coupons-wrapper no-result-coupon"
        style={{ margin: "0 auto", backgroundColor: "white" }}
      >
        <img loading="lazy" src={noResults} alt="No units found" />
        <h3>{en.noUnits.heading}</h3>
        <p>{en.noUnits.description}</p>
        <a href="/owner" style={{ width: "auto", height: "auto" }}>{en.noUnits.btnText}</a>
      </div>
    </div>
  );
};

export default NoUnits;
