import React, { useState, useCallback } from "react";
import "./QuickSearch.scss";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "components/Button/Button";
import CheckOutDropDown from "features/search-property/components/Search-Bar/components/CheckOutDropDown";
import AddGuestDropDown from "features/search-property/components/Search-Bar/components/AddGuestDropDown";
import useWindowSize from "hooks/useWindowSize";
import { useAppSelector, useAppDispatch } from "store/store";
import {
  searchSelector,
  activeFilters,
  setSearchActiveStatus,
} from "features/search-property/searchproperty.slice";

const LocationsDropdown = React.lazy(() =>
  import("features/search-property/components/Search-Bar/components/LocationsDropdown")
);

const CheckInDropDown = React.lazy(() =>
  import("features/search-property/components/Search-Bar/components/CheckInDropDown")
);

const QuickSearch = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const size: any = useWindowSize();

  const { locations, checkin, guests, checkout } =
    useAppSelector(searchSelector);
  const dispatch = useAppDispatch();
  const locationChange = useCallback(() => {
    const params: any = {};
    params["locations"] = locations.currentLocation ?? null;
    params["latitude"] = locations.lat ?? null;
    params["longitude"] = locations.long ?? null;
    params["from"] = checkin.formattedD ?? null;
    params["to"] = checkout.formattedD ?? null;
    params["guests"] =
      guests.currentGuests.adult > 0 ? guests.currentGuests.adult : null;
    params["page"] = 1;
    params["location"] = locations.id ?? null;
    dispatch(activeFilters(params));
    dispatch(setSearchActiveStatus(true)); // set search is active on clicking FIND button
    history.push("/search-results");
  }, [locations, checkin, checkout, guests, dispatch, history]);
  return (
    <div>
      <div className="container has-text-centered is-fluid mt-3 home-searchbar">
        <div className={`section home-search-bg is-medium`}>
          <h1 className="title newtitle">
            <span className="white">{t("home.title.findYour")} </span>
            <span className="blue">{t("home.title.perfPlace")}</span>
          </h1>
          <h1 className="title newtitle">
            <span className="white">{t("home.title.stayLong")} </span>
            <span className="blue">{t("home.title.youLike")}</span>
          </h1>
          <div className="box search-holder">
            {size.width > 1110 ? (
              <>
                <LocationsDropdown></LocationsDropdown>
                <CheckInDropDown></CheckInDropDown>
                <CheckOutDropDown></CheckOutDropDown>
                <AddGuestDropDown></AddGuestDropDown>
                <Button
                  buttonText={t("search.find")}
                  isPrimary={true}
                  classes="search-btn"
                  onClick={locationChange}
                ></Button>
              </>
            )
            :(
              <>
                <div className="mobile-home-filter">
                    <React.Suspense fallback={<div>Loading...</div>}>
                      <LocationsDropdown />
                    </React.Suspense>
                  <div className="MobileSearch-dates">
                    <CheckInDropDown></CheckInDropDown>
                    <CheckOutDropDown></CheckOutDropDown>
                  </div>
                  <AddGuestDropDown></AddGuestDropDown>
                  <Button
                    isPrimary={true}
                    buttonText={t("search.find")}
                    classes="MobileSearch-searchbtn"
                    onClick={locationChange}
                  ></Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickSearch;
