import { setToastState } from "components/Toast/Toast.slice";
import {
  setMailSent,
  ownerSelector,
  sendReportAsEmail,
  resetSendEmailState,
  NO_UNITS_DATA,
  clearState as clearReportState
} from "features/owner/owner.slice";
import { format } from "date-fns";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";

import "./PdfViewer.scss";
import { unitsSingleSelector } from "components/UnitsDropdown/units-dropdown.slice";
import en from "locale/en";
import noResults from "assets/images/coupons/no-results.webp";

const PdfViewer: React.FC = () => {
  const {
    dates,
    pdf,
    isReportFetching,
    isReportSuccess,
    mailSent,
    sendEmailSuccess,
    sendEmailFetching,
    errorMessage,
  } = useAppSelector(ownerSelector);
  const { options: selectedUnit } = useAppSelector(unitsSingleSelector);
  const dispatch = useAppDispatch();

  const generateOrSendReport = () => {
    const baseData = {
      units: selectedUnit.id,
      ...dates,
      generated_time: format(new Date(), "MMM. d, yyyy, hh:mm a"),
    };
    dispatch(
      sendReportAsEmail({ ...baseData, type: "email", raw_data: pdf.raw_data })
    );
    dispatch(setMailSent(true));
  };

  useEffect(() => {
    if (mailSent && sendEmailSuccess) {
      dispatch(
        setToastState({
          type: "success",
          message: "Report has been emailed!",
          dismiss: 3500,
        })
      );
      dispatch(setMailSent(false));
      dispatch(resetSendEmailState());
    }
  }, [dispatch, mailSent, sendEmailSuccess]);

  useEffect(() => {
    if (errorMessage && errorMessage !== NO_UNITS_DATA) {
      dispatch(
        setToastState({
          type: "danger",
          message: "Report generation failed. Please try again later",
          dismiss: 3500,
        })
      );
      dispatch(clearReportState());
    }
  }, [dispatch, errorMessage]);

  return (
    <div className="pdf-viewer">
      <div className="header-section">
        <h3 className="section-title">
          Revenue & Expense Report for{" "}
          {dates.from ? format(new Date(dates.from), "MMM, yyyy") : "---"} to{" "}
          {dates.to ? format(new Date(dates.to), "MMM, yyyy") : "---"}
        </h3>
        <div className="btn-group">
          <a
            className={`mr-3 button download-btn ${
              !isReportFetching && isReportSuccess ? "" : "is-disabled"
            }`}
            download={`Report - ${selectedUnit.id.slice(0, 25)}.pdf`}
            href={`data:application/pdf;base64,${pdf.data}`}
          >
            Download
          </a>
          <button
            className={`button email-btn ${
              sendEmailFetching ? "is-loading" : ""
            } ${!isReportFetching && isReportSuccess ? "" : "is-disabled"}`}
            onClick={(_) => generateOrSendReport()}
          >
            Send as Email
          </button>
        </div>
      </div>
      <hr className="divider" />
      <div className="mt-4">
        {isReportFetching ? (
          <div
            className="dashboard-main add-property m-0 mb-4"
            style={{ maxWidth: "100%" }}
          >
            <div className="list-property-wrapper m-0">
              <div className="list-property-grid m-0 ml-4 mr-4">
                <div
                  className="columns mt-4 map-loader"
                  style={{ minHeight: "650px" }}
                ></div>
              </div>
            </div>
          </div>
        ) : isReportSuccess ? (
          <iframe
            title="Report"
            width="100%"
            height="700px"
            id="viewer"
            src={`data:application/pdf;base64,${pdf.data}`}
          ></iframe>
        ) : errorMessage === NO_UNITS_DATA ? (
          <div
            className="coupons-wrapper no-result-coupon pb-0"
            style={{ margin: "0 auto", backgroundColor: "white", border: "none" }}
          >
            <img loading="lazy" src={noResults} alt="No data found" />
            <h3>{en.noUnitsFound}</h3>
            <p>{en.noUnitsFoundDesc}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PdfViewer;
