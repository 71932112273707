import React, { useState } from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

import { IData, IOffers } from "features/offers/offers.interface";
import Timer from "../../timer/Timer";
import defaultImg from "assets/images/offers/offer2.webp";
import viewallIcon from "assets/icons/viewall-arrow.webp";

import "./EvenCarousel.scss";
import { formattedTitle } from "features/offers/offers-utils";
import { useAppDispatch } from "store/store";
import { useHistory } from "react-router-dom";
import { clearSearchResult } from "features/search-result/searchresult.slice";
import { activeFilters } from "features/search-property/searchproperty.slice";
import { DirectionCheck } from "utils/classnameSwitch";
import { format } from "date-fns";

const EvenCarouselComponent: React.FC<IData> = ({
  offers,
  title,
  hasTimer,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [expiryTimestamp, setExpiryTimestamp] = useState(new Date(offers[0]?.expiry || "").getTime());
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: "0",
    variableWidth: true,
    prevArrow: (
      <div>
        <button
          className="carousel-arrow carousel-arrow-left"
          type="button"
        >
          <img loading="lazy" src={viewallIcon} alt="view all"></img>
        </button>
      </div>
    ),
    nextArrow: (
      <div>
        <button
          className="carousel-arrow carousel-arrow-right"
          type="button"
        >
          <img loading="lazy" src={viewallIcon} alt="view all"></img>
        </button>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          variableWidth: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          variableWidth: false,
        },
      },
    ],
    afterChange: (index: number) => setExpiryTimestamp(new Date(offers[index]?.expiry || "").getTime())
  };
  const showOfferDetails = (id: any) => {
    const params: any = {};
    params['offer_id'] = id;
    params['page'] = null;
    dispatch(clearSearchResult());
    dispatch(activeFilters(params));
    history.push("/search-results");
  }

  const buildStayText = (data: IOffers) => {
    let text = "";
    if (data?.arrival_date_gt && data?.departure_date_lt) {
      text = `${t('offers.fromTxt')} ${format(new Date(data?.arrival_date_gt), "LLL dd")} ${t('offers.toText')} ${format(new Date(data.departure_date_lt), "LLL dd")}`;
      text = data?.length_of_stay ? `${t('offers.losTxt1')} ${data.length_of_stay} ${t('offers.losTxt3')} ${text}` : `${t('offers.losTxt1')} ${text}`;
      return text;
    }
    if (data?.length_of_stay) {
      text = `${t('offers.losTxt1')} ${data.length_of_stay} ${t('offers.losTxt2')}`
    }
    return text;
  }

  return (
    <>
      <h3 className="layout-grid offer-sections-header" dir={DirectionCheck()}>
        {title} <Timer expiryTimestamp={expiryTimestamp} />
      </h3>
      <div className="carousel-wrapper even-carousel">
        <Slider {...carouselSettings}>
          {
            offers.map((data, key) => {
              return <div className={`offers-wrapper ${offers.length === 1 ? 'w-100vw' : ''}`} key={key}>
                <div className={`offers-inner-wrapper ${offers.length === 1 ? 'even-carousel-single-data' : ''}`} dir={DirectionCheck()}>
                  <div className="deal-img">
                    <img loading="lazy" src={data.image || defaultImg} alt="" />
                  </div>
                  <div className="deal-content">
                    {formattedTitle(data.title, data.highlight_text, "category", "deal-offer", false)}
                    {formattedTitle(data.description_1, data.highlight_text, "deal-text text-dark", "deal-offer", true)}
                    {data.description_2 ? <div style={{ marginTop: "5px" }}>{formattedTitle(data.description_2, data.highlight_text, "desc", "deal-offer", true)}</div> : null}
                    {((data.arrival_date_gt && data.departure_date_lt) || data.length_of_stay) ? <p className="desc-green">{buildStayText(data)}</p> : null}
                    <button className="desc-button" type="button" onClick={() => { showOfferDetails(data.id) }}>{t('offers.view')}</button>
                  </div>
                </div>
              </div>
            })
          }
        </Slider>
      </div>
    </>
  );
};

export default EvenCarouselComponent;
