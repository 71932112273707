import React from "react";

import logo from 'assets/images/logo.webp';
import './AuthLayout.scss';

const AuthLayout: React.FC = (props) => {
    const { children } = props;
    return (
        <div className="columns auth-layout is-mobile m-0">
            <div className="column image-wrapper left">
            </div>
            <div className="column right">
                <div className="content-area">
                    <div className="staycae-logo-wrapper">
                       <a href="/home"> <img className="staycae-logo" src={logo} alt="Staycae" loading="lazy"/></a>
                    </div>
                    { children }
                </div>
            </div>
        </div>
    );
};
  
export default AuthLayout;
