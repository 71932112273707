import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.scss";
import { ILocations } from "./locations.interface";
import useLocalStorage from "hooks/useLocalStorage";

interface LocationsProps {
  locations: ILocations[];
}
const CarouselArea: React.FC<LocationsProps> = ({ locations }) => {
  // eslint-disable-next-line
  const [contDir, setContDir] = useLocalStorage("contentDir", "ltr");
  const settings = {
    className: "center",
    infinite: true,
    slidesToShow: 2,
    speed: 500,
    dots: true,
    autoplay: false,
    rtl: contDir === 'rtl',
    dotsClass: contDir === 'rtl' ? "textRightAlign slick-dots" : "slick-dots"
  };
  return (
    <Slider {...settings}>
      {locations.map((item, index) => (
        <div className="areas" key={index}>
          <div className="attraction-title-holder">
            <span>{item.name}</span>
          </div>
          <img loading="lazy"
            src={`${item.image}`}
            alt="downtown"
          ></img>
        </div>
      ))}
    </Slider>
  );
};

export default CarouselArea;
