import danger from 'assets/icons/toast/danger.webp';
import info from 'assets/icons/toast/info.webp';
import success from 'assets/icons/toast/success.webp';
import warning from 'assets/icons/toast/warning.webp';
import close from 'assets/icons/toast/close.webp';

export const ToastIcons: {type: string; iconUrl: string}[] = [
    {
        type: 'success',
        iconUrl: success
    },
    {
        type: 'info',
        iconUrl: info
    },
    {
        type: 'warning',
        iconUrl: warning
    },
    {
        type: 'danger',
        iconUrl: danger
    }
];

export const closeButton = close;
