import React from "react";
import { useTranslation } from "react-i18next";
import PlayButton from "assets/icons/play.webp";
import { useState } from "react";
import { setToastState } from "components/Toast/Toast.slice";
import { useAppDispatch } from "store/store";
import { format } from "date-fns";
import { CouponsData } from "../coupons.interface";
import { useLocation } from "react-router-dom";
import { setSelectedCoupon } from "../coupons.slice";

const Coupon: React.FC<{
  data: CouponsData;
  applyCoupon?: (id: string) => any;
}> = ({ data, applyCoupon }) => {
  const { t } = useTranslation();
  const [expanded, setExpandedStatus] = useState(false);
  const dispatch = useAppDispatch();
  const [path] = useState(useLocation().pathname);

  const copyCouponCode = (text: string) => {
    navigator.clipboard.writeText(text);
    dispatch(
      setToastState({
        type: "info",
        message: "referral.toastMsg.copy",
        dismiss: 3500,
      })
    );
  };

  return (
    <article className={`message coupons-grid`}>
      <div
        className={`message-header ${expanded ? "rotate-90" : ""}`}
        data-action="collapse"
      >
        <div className="accordian-text">
          <div className="left-section">
            <span className="name">{data.code}</span>
            <span className="discount">
              (
              {data.discount
                ? `${data.discount.split(".")[0]}${
                    data.discount_type === 1 ? "%" : " AED"
                  } ${
                    data.discount_type === 1
                      ? t("coupons.disc")
                      : t("coupons.flat")
                  }`
                : ""}
              )
            </span>
            {!path.includes("/stay-details") ? (
              <span
                className="copy-button"
                title="Copy"
                onClick={() => copyCouponCode(data.code)}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
              </span>
            ) : (
              <div 
                className={`stay-details-coupon-btn-wrapper ${data?.is_applicable ? '' : 'disable-Coupon'}` }
                onClick={(_) => {
                  dispatch(setSelectedCoupon(data.id));
                  applyCoupon && applyCoupon(data.id);
                }}
              >
                <span className="stay-details-coupon-btn">
                  {t("payment.applyCoupon")}
                </span>
              </div>
            )}

            <p className="description">
              {data.description
                ? data.description
                : `${t("coupons.get")} ${data.discount.split(".")[0]}% ${t(
                    "coupons.offBook"
                  )}`}
            </p>
          </div>
        </div>
        {data.valid_to ? (
          <div className="validity">
            {t("coupons.valid")}
            <span className="date">
              {format(new Date(data.valid_to), "dd-MMM-yyyy")}
            </span>
          </div>
        ) : null}
        {data.conditions.length ? (
          <span
            className={`play-button ${expanded ? "rotate-90" : ""}`}
            title="More Details"
            onClick={(_) =>
              expanded ? setExpandedStatus(false) : setExpandedStatus(true)
            }
          >
            <img loading="lazy"
              src={PlayButton}
              alt="Play button"
              className="play-button-img"
            />
          </span>
        ) : null}
      </div>

      <div
        id={`${data.id}`}
        className={`message-body is-collapsible ${
          expanded ? "" : "collapse-message-body"
        } ${data.conditions.length ? "" : "hide-acc"} ${
          path.includes("/stay-details") && expanded ? "height-auto" : ""
        } ${path.includes("/stay-details") && !expanded ? "height-0" : ""}`}
        data-parent="accordian_parent"
      >
        <div className="message-body-content">
          <p className="list-heading">{t("coupons.terms")}</p>
          {data.conditions
            ? data.conditions.map((condition) => {
                return (
                  <div className="list-item">
                    <span className="bullet-wrapper">
                      <span className="bullet"></span>
                    </span>
                    <span className="item">{condition}</span>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </article>
  );
};

export default Coupon;
