import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BookingProps } from "../booking.interface";
import placeholder from "assets/images/result/placeholder.webp";
import { format } from "date-fns";
import { useAppDispatch } from "store/store";
import {
  downloadInvoice,
  manageCancelBooking,
  bookingProfileSelector,
  getBookings,
  clearInvoiceError,
} from "../booking.slice";
import {
  updateExtendStay,
  updateSelection,
} from "features/search-property/searchproperty.slice";
import add from "date-fns/add";
import { useHistory, useLocation } from "react-router";
import { useAppSelector } from "store/store";
import { updateDates } from "features/stay-details/stay-details.slice";
import CancelConfirmPopup from "./CancelConfirmPopup";
import { setToastState } from "components/Toast/Toast.slice";

const BookingCard: React.FC<BookingProps> = (data: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { isCancelled, invoiceError } = useAppSelector(bookingProfileSelector);
  const [cancelID, setCancelID] = useState("");
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [showCancel, setShowCancel] = useState(false);
  useEffect(() => {
    function handleResize() {
      setDimensions(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleRedirection = (e: any, data: any) => {
    e.preventDefault();
    dispatch(updateDates(false));
    history.push(`/stay-details/${data.room_category.slug}`);
  };

  const confirmCancel = () => {
    setShowCancel(true);
  };
  const cancelBooking = async (data: any) => {
    const params = {
      booking_id: data.id,
    };
    setCancelID(data.id);
    try {
      //eslint-disable-next-line
      const user = await dispatch(manageCancelBooking(params)).unwrap();
      dispatch(getBookings());
    } catch (err) { }
  };
  const extendStay = (e: any, item: any) => {
    e.preventDefault();
    const result = add(new Date(data.departure_date), {
      days: 1,
    });
    const formattedDate = format(new Date(data.departure_date), "dd LLL, yyyy"); // new check-in date is the previous departure date
    const requestDate = format(new Date(data.departure_date), "yyyy-MM-dd");
    const formattedCheckout = format(result, "dd LLL, yyyy");
    const requestCheckout = format(result, "yyyy-MM-dd");
    dispatch(
      updateSelection({
        category: "currentFrom",
        value: formattedDate,
        formattedD: requestDate,
        master: "checkin",
      })
    );
    dispatch(
      updateSelection({
        category: "currentTo",
        value: formattedCheckout,
        formattedD: requestCheckout,
        master: "checkout",
      })
    );
    dispatch(updateExtendStay(true));
    history.push(`/stay-details/${data.room_category.slug}`, {
      from: location.pathname,
    });
  };
  const downloadReceipt = (e: any) => {
    e.preventDefault();
    dispatch(clearInvoiceError());
    dispatch(downloadInvoice(data.reservation_id));
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (invoiceError) {
      dispatch(clearInvoiceError());
      dispatch(
        setToastState({
          type: "danger",
          message: t("payment.errorMsg"),
          dismiss: 3500,
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, invoiceError]);

  return (
    <>
      <div className="columns wishlist-columns">
        <div
          className={`wishlist-image-container ${data.room_category.image ? "" : "placeholder-image-style"
            } ${!data.is_active && !data.room_category.image ? "grayscale" : ""}`}
        >
          {data.room_category.image ? (
            <img loading="lazy"
              src={data.room_category.image}
              className={`wishlist-image ${!data.is_active ? "grayscale" : ""}`}
              alt={data.room_category.name}
            ></img>
          ) : (
            <img loading="lazy"
              src={placeholder}
              alt="placeholder-img"
              className="wishlist-image"
            ></img>
          )}

          {data.status.toLowerCase() === "cancelled" && (
            <span className="cancelled-wrap">
              <span className="cancelled-text">{t("bookings.cancelled")}</span>
            </span>
          )}

          {(data.status.toLowerCase() === "confirmed" || data.status.toLowerCase() === "arrived" ||  data.status.toLowerCase() === "departed") &&
            data.is_active && (
              <span className="active-wrap">
                <span className="active-text">{t("bookings.active")}</span>
              </span>
            )}
        </div>
        <div className="wishlist-contents booking-content">
          <div className="booking-container booking-wrapper">
            <div className={`wishlist-name ${!data.is_active ? "disabled-card" : ""
              }`} >
              <a
                href={`/stay-details/${data.room_category.slug}`}
                className="result-card-title highlight-onhover"
                style={{ cursor: "pointer" }}
                onClick={(e) => handleRedirection(e, data)}
              >
                {data.room_category.nice_name || data.room_category.name}
              </a>
              {!data.is_active && (
                <span className=" unavailable-unit orange">
                  Room Unavailable
                </span>
              )}
              <div
                className={`booking-subtitle ${data.status.toLowerCase() !== "confirmed"
                    ? "extra-bottom"
                    : ""
                  }`}
              >
                {data.room_category.sub_title}
              </div>
            </div>

            <div className="booking-right-content">
              <div className="booking-price">
                AED {data.rate}
              </div>
              <div className="booking-booked-date">
                {t("bookings.bookedon")}{" "}
                {format(new Date(data.booking_date), "dd-MMM-yyyy")}
              </div>
            </div>
          </div>
          <div className="booking-container end check-wrap">
            <div className="booking-container check">
              <div className="booking-dates">
                <div className="booking-title">{t("bookings.checkin")}</div>
                <div className="booking-res">
                  {format(new Date(data.arrival_date), "dd-MMM-yyyy")}
                </div>
              </div>
              <div className="booking-dates">
                <div className="booking-title">{t("bookings.checkout")}</div>
                <div className="booking-res">
                  {format(new Date(data.departure_date), "dd-MMM-yyyy")}
                </div>
              </div>
            </div>
            {dimensions > 768 && (
              <>
                {" "}
                {data.tab !== "Upcoming" && (
                  <div className="booking-invoice book-btn-wrap">
                    <button
                      className={`is-primary staycae-btn button ml-4 book-btn ${!data.is_active ? "grayscale" : ""
                        }`}
                      onClick={(e) => handleRedirection(e, data)}
                    >
                      {t("bookings.bookagain")}
                    </button>
                  </div>
                )}
                {data.tab === "Upcoming" &&
                  data.status.toLowerCase() !== "cancelled" && (
                    <div className="booking-invoice book-btn-wrap">
                      <button
                        className={`is-primary staycae-btn button ml-4 book-btn ${!data.is_active ? "grayscale" : ""
                          }`}
                        onClick={(e) => extendStay(e, data)}
                      >
                        {t("bookings.extend")}
                      </button>
                    </div>
                  )}
              </>
            )}
          </div>
          {dimensions < 768 && (
            <div className={`${data.guests ? "show-div" : "hide-div"}`}>
              <div className="booking-title">{t("bookings.guests")}</div>
              <div className="booking-res">
                {data.guests} {t("payment.adults")}
              </div>
            </div>
          )}

          <div className="booking-container end check-wrap">
            {dimensions < 768 && (
              <>
                {data.tab !== "Upcoming" && (
                  <div className="booking-invoice book-btn-wrap">
                    <button
                      className={`is-primary staycae-btn button ml-4 book-btn ${!data.is_active ? "grayscale" : ""
                        }`}
                      onClick={(e) => handleRedirection(e, data)}
                    >
                      {t("bookings.bookagain")}
                    </button>
                  </div>
                )}
                {data.tab === "Upcoming" &&
                  data.status.toLowerCase() !== "cancelled" && (
                    <div className="booking-invoice book-btn-wrap">
                      <button
                        className={`is-primary staycae-btn button ml-4 book-btn ${!data.is_active ? "grayscale" : ""
                          }`}
                        onClick={(e) => extendStay(e, data)}
                      >
                        {t("bookings.extend")}
                      </button>
                    </div>
                  )}
              </>
            )}
            {dimensions > 768 && (
              <div className={`${data.guests ? "show-div" : "hide-div"}`}>
                <div className="booking-title">{t("bookings.guests")}</div>
                <div className="booking-res">
                  {data.guests} {t("payment.adults")}
                </div>
              </div>
            )}
            {(data.tab === "Past" ||
              (data.tab === "Upcoming" &&
                !(data.is_cancellable && data.is_refundable))) &&
              data.status.toLowerCase() !== "cancelled" && (
                <div className="booking-invoice download">
                  <span
                    onClick={(e) => downloadReceipt(e)}
                    className="hide-mob"
                  >
                    {t("bookings.download")}
                  </span>
                  <span className="show-mob"  onClick={(e) => downloadReceipt(e)}>{t("bookings.invoice")}</span>
                </div>
              )}
            {data.tab === "Upcoming" &&
              data.is_cancellable &&
              data.is_refundable &&
              data.status.toLowerCase() === "confirmed" && (
                <div className={`booking-invoice cancel-btn`}>
                  <button
                    className={`is-primary staycae-btn button ml-4 book-btn  ${isCancelled && cancelID === data.id ? "is-loading" : ""
                      } ${!data.is_active ? "grayscale" : ""}`}
                    onClick={(e) => confirmCancel()}
                  >
                    {t("bookings.cancel")}
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
      {showCancel ? (
        <CancelConfirmPopup
          onClose={(status) => setShowCancel(false)}
          handleCancelBoooking={(val: any) => cancelBooking(val)}
          data={data}
          isCancelled={isCancelled}
          cancelID={cancelID}
        />
      ) : null}
    </>
  );
};

export default BookingCard;
